import React from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
import logo from '../assets/images/logo.png'; // Import your logo image

const Navbar = React.forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <div ref={ref} {...props}>
      <Box bg='primary.default'>
        <Flex as="nav" alignItems="center" justifyContent="center">
          <Box className="logo">
            <Image src={logo} alt="Rick's Logo" />
          </Box>
        </Flex>
      </Box>
    </div>
  );
});

export default Navbar;
