import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  styles: {
    global: {
      'html, body': {
        margin: '0',
        padding: '0',
        fontFamily: '8xl Academy Engraved, sans-serif',
        height: '100%',
      },
    },
  },
  colors: {
    primary: {
      default: '#8A3463',
      dark: '#483248',
      light: '#E6E6FA'
    },
    secondary: {
      default: '#BC4347'
    },
    tertiary: {
      default: '#2D4463'
    },
  },
});

export default theme;
