import React, { useRef, useState, useEffect } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './themes/theme';
import Navbar from './components/Navbar';
import Display from './components/Display';
import { NavbarHeightContext } from './context/NavbarHeightContext';

const App: React.FC = () => {
  const [navbarHeight, setNavbarHeight] = useState(0);
  const navbarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (navbarRef.current) {
      setNavbarHeight(navbarRef.current.clientHeight);
    }

    const handleResize = () => {
      if (navbarRef.current) {
        setNavbarHeight(navbarRef.current.clientHeight);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <NavbarHeightContext.Provider value={navbarHeight}>
        <Navbar ref={navbarRef} />
        <Display />
      </NavbarHeightContext.Provider>
    </ChakraProvider>
  );
}

export default App;
