import React, { useState, useEffect } from 'react';
import { Box, Flex, useTheme } from '@chakra-ui/react';
import { useNavbarHeight } from '../context/NavbarHeightContext';

const Display: React.FC = () => {
  const navbarHeight = useNavbarHeight();
  const theme = useTheme();
  const [skewAngle, setSkewAngle] = useState(-20); // Initialize with -20 degrees

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      // Generate a random skew angle between -50 and 50
      const randomSkewAngle = Math.floor(Math.random() * 101) - 50;
      setSkewAngle(randomSkewAngle);

      // Prevent the default scroll behavior
      event.preventDefault();
    };

    window.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);

  const aspectRatio = window.innerWidth / (window.innerHeight - navbarHeight);
  const viewBoxHeight = 100;
  const viewBoxWidth = aspectRatio * viewBoxHeight;
  const skewAngleRadians = (skewAngle * Math.PI) / 180;
  const trapezoidBaseWidth = (viewBoxWidth - Math.tan(skewAngleRadians) * viewBoxHeight) / 2;

  return (
    <Flex
      direction="column"
      height={`calc(100vh - ${navbarHeight}px)`}
      width="100vw"
      overflow="hidden"
    >
      <Box as="svg" width="100%" height="100%" viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}>
        <polygon
          points={`
            0,${viewBoxHeight}
            0,0
            ${trapezoidBaseWidth},0
            ${trapezoidBaseWidth + Math.tan(skewAngleRadians) * viewBoxHeight},${viewBoxHeight}`}
          fill={theme.colors.secondary.default}
        />
        <polygon
          points={`
            ${trapezoidBaseWidth},0 
            ${trapezoidBaseWidth + Math.tan(skewAngleRadians) * viewBoxHeight},${viewBoxHeight} 
            ${trapezoidBaseWidth + Math.tan(skewAngleRadians) * viewBoxHeight + 5},${viewBoxHeight}
            ${trapezoidBaseWidth + 5},0
          `}
          fill={theme.colors.tertiary.default}
        />
        <polygon
          points={`
            ${trapezoidBaseWidth + 5},0 
            ${trapezoidBaseWidth + Math.tan(skewAngleRadians) * viewBoxHeight + 5},${viewBoxHeight}
            ${trapezoidBaseWidth + Math.tan(skewAngleRadians) * viewBoxHeight + 10},${viewBoxHeight}
            ${trapezoidBaseWidth + 10},0
          `}
          fill={theme.colors.secondary.default}
        />
        <polygon
          points={`
            ${trapezoidBaseWidth + Math.tan(skewAngleRadians) * viewBoxHeight + 10},${viewBoxHeight} 
            ${viewBoxWidth},${viewBoxHeight}
            ${viewBoxWidth},0
            ${trapezoidBaseWidth + 10},0
          `}
          fill={theme.colors.tertiary.default}
        />
      </Box>
    </Flex>
  );
};

export default Display;
